import { BaseReco } from "@cruncho/cruncho-shared-types";

// Get the closest valid time after now, eventStart contains UTC timestring(s)
export const getFirstAvailableTime = (
	eventStart: string | string[] | undefined,
): Date | false => {
	if (!eventStart) {
		return false;
	}

	const now = new Date();

	if (Array.isArray(eventStart)) {
		// Find the first time after now
		const firstAvailableTime = eventStart
			.map((dateString) => new Date(dateString))
			.filter((date) => date > now)
			.sort((a, b) => +a - +b)[0]; // Sort and get the earliest future time

		return firstAvailableTime || false;
	}

	// If eventStart is a single date string
	const eventDate = new Date(eventStart);
	return eventDate > now ? eventDate : false;
};

// Sort recommendations by the first available time
export const sortByFirstAvailableTime = <T extends BaseReco>(
	recommendations: T[],
) =>
	recommendations
		.filter(({ eventStart }) => getFirstAvailableTime(eventStart)) // Filter valid recommendations
		.sort(
			(recoA, recoB) =>
				+getFirstAvailableTime(recoA.eventStart) -
				+getFirstAvailableTime(recoB.eventStart),
		);
