import { z } from "zod";

export const EMLevelPermissionEnum = {
	ORGANIZER: "ORGANIZER",
	MODERATOR: "MODERATOR",
	ADMIN: "ADMIN",
	TRUSTED: "TRUSTED ORGANIZER",
} as const;

export type EMLevelPermission =
	typeof EMLevelPermissionEnum[keyof typeof EMLevelPermissionEnum];

export const emUserPermissionSchema = z.record(
	z.nativeEnum(EMLevelPermissionEnum),
);
export type EMUserPermission = z.infer<typeof emUserPermissionSchema>;

export const userSchema = z.object({
	_id: z.string(),
	company: z.string(),
	email: z.string(),
	disabled: z.boolean().optional(),
	groups: z.string().array().optional(),
	lastLogin: z.string(),
	name: z.string(),
	phone: z.string(),
	permissions: emUserPermissionSchema,
	quizModerator: z.record(z.boolean()).optional(),
	surname: z.string(),
});

export type User = z.infer<typeof userSchema>;

export const groupSchema = z.object({
	_id: z.string(),
	destinationSlug: z.string(),
	name: z.string(),
	members: z.string().array(),
});

export type Group = z.infer<typeof groupSchema>;
