import { z } from "zod";
import {
	AppLanguageCode,
	appLanguageSchema,
	languagesISO639,
	languagesISO639_1_Schema,
} from "../languages";

/**
 * Generic names for APIs
 */
export const ALL_APIS = [
	"crunchoEvent",
	"cruncho",
	"datatourisme",
	"eventim",
	"eventbrite",
	"facebookEvent",
	"facebook",
	"fork",
	"foursquare",
	"google",
	"hotelscombined",
	"kaxig",
	"opendatasoft",
	"osemedia",
	"reservix",
	"ringbillet",
	"skaraborg",
	"songkick",
	"ticketco",
	"ticketmaster",
	"tripadvisor",
	"viator",
	"yelp",
	"youtube",
] as const;
export type AvailableApi = (typeof ALL_APIS)[number];
export type AvailableApis = AvailableApi[];
export enum AvailableApisEnum {
	"crunchoEvent" = "crunchoEvent",
	"cruncho" = "cruncho",
	"datatourisme" = "datatourisme",
	"eventbrite" = "eventbrite",
	"eventim" = "eventim",
	"facebookEvent" = "facebookEvent",
	"facebook" = "facebook",
	"fork" = "fork",
	"foursquare" = "foursquare",
	"google" = "google",
	"hotelscombined" = "hotelscombined",
	"kaxig" = "kaxig",
	"opendatasoft" = "opendatasoft",
	"osemedia" = "osemedia",
	"reservix" = "reservix",
	"ringbillet" = "ringbillet",
	"skaraborg" = "skaraborg",
	"songkick" = "songkick",
	"ticketco" = "ticketco",
	"ticketmaster" = "ticketmaster",
	"tripadvisor" = "tripadvisor",
	"viator" = "viator",
	"yelp" = "yelp",
	"youtube" = "youtube",
}
export const availableApisSchema = z.nativeEnum(AvailableApisEnum);

export enum RecoApiEnum {
	/**
	 * Reco created from an event in the EM
	 */
	"crunchoEvent" = "crunchoEvent",
	/**
	 * Reco created in the CMS
	 */
	"crunchoReco" = "crunchoReco",
	"datatourismReco" = "datatourismReco",
	"forkReco" = "forkReco",
	"foursquareReco" = "foursquareReco",
	"googleReco" = "googleReco",
	"hotelscombinedReco" = "hotelscombinedReco",
	"opendatasoftReco" = "opendatasoftReco",
	"osemediaReco" = "osemediaReco",
	"ringbilletReco" = "ringbilletReco",
	"scrapedEvent" = "scrapedEvent",
	"tripadvisorReco" = "tripadvisorReco",
	"vastReco" = "vastReco",
	"viatorReco" = "viatorReco",
	"yelpReco" = "yelpReco",
}
export const recoApiSchema = z.nativeEnum(RecoApiEnum);

export function convertRecoApiToAvailableAPI(
	recoApi: RecoApiEnum,
): AvailableApisEnum {
	switch (recoApi) {
		case RecoApiEnum.crunchoEvent:
			return AvailableApisEnum.crunchoEvent;
		case RecoApiEnum.crunchoReco:
			return AvailableApisEnum.cruncho;
		case RecoApiEnum.datatourismReco:
			return AvailableApisEnum.datatourisme;
		case RecoApiEnum.forkReco:
			return AvailableApisEnum.fork;
		case RecoApiEnum.foursquareReco:
			return AvailableApisEnum.foursquare;
		case RecoApiEnum.googleReco:
			return AvailableApisEnum.google;
		case RecoApiEnum.hotelscombinedReco:
			return AvailableApisEnum.hotelscombined;
		case RecoApiEnum.opendatasoftReco:
			return AvailableApisEnum.opendatasoft;
		case RecoApiEnum.osemediaReco:
			return AvailableApisEnum.osemedia;
		case RecoApiEnum.ringbilletReco:
			return AvailableApisEnum.ringbillet;
		case RecoApiEnum.tripadvisorReco:
			return AvailableApisEnum.tripadvisor;
		case RecoApiEnum.vastReco:
			return AvailableApisEnum.skaraborg;
		case RecoApiEnum.viatorReco:
			return AvailableApisEnum.viator;
		case RecoApiEnum.yelpReco:
			return AvailableApisEnum.yelp;
		default:
			throw new Error(`Unknown RecoApiEnum: ${recoApi}`);
	}
}

export function convertAvailableApiToRecoApi(
	availableApi: AvailableApisEnum,
): RecoApiEnum {
	switch (availableApi) {
		case AvailableApisEnum.crunchoEvent:
			return RecoApiEnum.crunchoEvent;
		case AvailableApisEnum.cruncho:
			return RecoApiEnum.crunchoReco;
		case AvailableApisEnum.datatourisme:
			return RecoApiEnum.datatourismReco;
		case AvailableApisEnum.eventim:
			return RecoApiEnum.scrapedEvent;
		case AvailableApisEnum.eventbrite:
			return RecoApiEnum.scrapedEvent;
		case AvailableApisEnum.facebookEvent:
			return RecoApiEnum.scrapedEvent;
		case AvailableApisEnum.fork:
			return RecoApiEnum.forkReco;
		case AvailableApisEnum.foursquare:
			return RecoApiEnum.foursquareReco;
		case AvailableApisEnum.google:
			return RecoApiEnum.googleReco;
		case AvailableApisEnum.hotelscombined:
			return RecoApiEnum.hotelscombinedReco;
		case AvailableApisEnum.kaxig:
			return RecoApiEnum.scrapedEvent;
		case AvailableApisEnum.opendatasoft:
			return RecoApiEnum.opendatasoftReco;
		case AvailableApisEnum.osemedia:
			return RecoApiEnum.osemediaReco;
		case AvailableApisEnum.reservix:
			return RecoApiEnum.scrapedEvent;
		case AvailableApisEnum.ringbillet:
			return RecoApiEnum.ringbilletReco;
		case AvailableApisEnum.skaraborg:
			return RecoApiEnum.vastReco;
		case AvailableApisEnum.songkick:
			return RecoApiEnum.scrapedEvent;
		case AvailableApisEnum.ticketco:
			return RecoApiEnum.scrapedEvent;
		case AvailableApisEnum.ticketmaster:
			return RecoApiEnum.scrapedEvent;
		case AvailableApisEnum.tripadvisor:
			return RecoApiEnum.tripadvisorReco;
		case AvailableApisEnum.viator:
			return RecoApiEnum.viatorReco;
		case AvailableApisEnum.yelp:
			return RecoApiEnum.yelpReco;
		default:
			throw new Error(`Unknown AvailableApisEnum: ${availableApi}`);
	}
}

export enum MapFieldToApiEnum {
	"crunchoEvent" = "crunchoEvent",
	"crunchoReco" = "cruncho",
	"datatourismReco" = "datatourisme",
	"eventimReco" = "eventim",
	"eventbrite" = "eventbrite",
	"facebookEvent" = "facebookEvent",
	"facebookReco" = "facebook",
	"forkReco" = "fork",
	"foursquareReco" = "foursquare",
	"googleReco" = "google",
	"hotelscombinedReco" = "hotelscombined",
	"opendatasoftReco" = "opendatasoft",
	"osemediaReco" = "osemedia",
	"reservixReco" = "reservix",
	"ringbilletReco" = "ringbillet",
	"songkickReco" = "songkick",
	"tmReco" = "ticketmaster",
	"tripadvisorReco" = "tripadvisor",
	"vastReco" = "skaraborg",
	"viatorReco" = "viator",
	"yelpReco" = "yelp",
}

export type RecoCategories = Array<string>;

export const RATINGS_APIS = [
	AvailableApisEnum.crunchoEvent,
	AvailableApisEnum.datatourisme,
	AvailableApisEnum.fork,
	AvailableApisEnum.foursquare,
	AvailableApisEnum.google,
	AvailableApisEnum.tripadvisor,
	AvailableApisEnum.viator,
	AvailableApisEnum.yelp,
] as const;
export type RatingApi = (typeof RATINGS_APIS)[number];

export const apiRatingSchema = z.object({
	api: z.enum(RATINGS_APIS),
	value: z.number(),
});
export interface ApiRating {
	api: RatingApi;
	value: number;
}

export const ALL_LEVEL_1 = [
	"restaurants",
	"hotels",
	"visit",
	"events",
] as const;

export const l1Schema = z.enum(ALL_LEVEL_1);

export type L1 = z.infer<typeof l1Schema>;

// TODO: Just have weekday not close/open
export const OpenWeekDaySchema = z.object({
	api: availableApisSchema,
	closeTime: z.string(),
	closeWeekday: z.number(),
	militaryClose: z.number().optional(),
	militaryOpen: z.number().optional(),
	openTime: z.string(),
	openWeekday: z.number(),
});
export type OpenWeekDay = z.infer<typeof OpenWeekDaySchema>;

export enum WeekDay {
	SUNDAY = 0,
	MONDAY = 1,
	TUESDAY = 2,
	WEDNSDAY = 3,
	THURSDAY = 4,
	FRIDAY = 5,
	SATURDAY = 6,
}

export const photoSchema = z.object({
	api: z.nativeEnum(AvailableApisEnum),
	url: z.string().url(),
	alt: z.string().optional(),
	isActive: z.boolean().optional(),
	credit: z.string().optional(),
	ratio: z.string().optional(),
	width: z.number().optional(),
	height: z.number().optional(),
	name: z.string().optional(),
});

export type Photo = z.infer<typeof photoSchema>;

export const liveEventPhotoSchema = photoSchema.pick({
	url: true,
	credit: true,
	alt: true,
	name: true,
	width: true,
	height: true,
});

export type LiveEventPhoto = z.infer<typeof liveEventPhotoSchema>;

export const recoNameQuerySchema = z.object({
	name: z.string(),
	hide: z.boolean().optional(),
	isSponsored: z.boolean().optional(),
	isPinned: z.boolean().optional(),
	l1: l1Schema.optional(),
	destinationSlug: z.string(),
});
export type RecoNameQuery = z.infer<typeof recoNameQuerySchema>;

export const reviewSchema = z.object({
	api: z.enum(ALL_APIS),
	authorName: z.string(),
	authorUrl: z.string().optional(),
	comment: z.string().default(""),
	createdAt: z.string().optional(),
	language: languagesISO639_1_Schema
		.or(appLanguageSchema.optional())
		.or(
			z.string().transform<languagesISO639>((str) => {
				if (str === "iw") return "he";
				throw new Error(`Unknown language: ${str}`);
			}),
		)
		.optional(),
	likesCount: z.number().optional(),
	moreReviewsUrl: z.string().optional(),
	rating: z.number().optional(),
	reviewUrl: z.string().optional(),
	sortOrder: z.number().optional(),
});
export type Review = z.infer<typeof reviewSchema> &
	Partial<Record<AppLanguageCode, string>>;

export const targetGroupSchema = z.object({
	value: z.string(),
	label: z.string(),
});

export type TargetGroup = z.infer<typeof targetGroupSchema>;

export const viatorDataSchema = z.object({
	activity_code: z.string().optional(),
	operates_frequency: z.string().optional(),
	departure_point: z.string().optional(),
	departure_time: z.string().optional(),
	departure_time_comments: z.string().optional(),
	description: z
		.string()
		.transform((original) => {
			const splitCamelCaseAndDot = (
				original2: string,
				separator: string = "<br />",
			) =>
				original2 &&
				original2
					.replace(/([a-zåäö])([A-ZÅÄÖ])/g, `$1${separator}$2`)
					.replace(/\.([A-Z])/g, `.${separator}$1`);

			let res = original.replace("Itinerary", "<p>Itinerary</p>");
			res = res.replace(/Duration\:/g, "<p>Duration: </p>");
			res = res.replace(/Stop At\:/g, "<p>Stop At:</p>");
			res = splitCamelCaseAndDot(res);
			return res;
		})
		.optional(),
	duration: z.string().optional(),
	additional_info: z.string().optional(),
});

export type ViatorData = z.infer<typeof viatorDataSchema>;

export const videoSchema = z.object({
	id: z.number(),
	title: z.string().optional(),
	videoId: z.string(),
});

export type Video = z.infer<typeof videoSchema>;
export type Videos = Video[];

export const socialMediaSchema = z.object({
	name: z.string(),
	url: z.string(),
});

export interface ListRecosQueryParams {
	destination: string;
	categories?: string;
	page?: string; // Is a number but arrive as a string from Fastify query
}
export const reindexParamsSchema = z.object({
	destinationSlug: z.string().optional(),
	ids: z.number().array().optional(),
	minimumId: z.number().optional(),
	maximumId: z.number().optional(),
});

export type ReindexParams = z.infer<typeof reindexParamsSchema>;
