import { z } from "zod";
import {
	liveEventPhotoSchema,
	videoSchema,
	availableApisSchema,
	socialMediaSchema,
} from "../recommendation/properties";
import { rsvpSchema } from "../rsvp";
import { dateSchema } from "../utilities";

export const REJECTED = "rejected";
export const POSTED = "posted";
export const PENDING = "pending";
export const DRAFT = "draft";
export const DELETED = "deleted";
export const ERROR_POSTING = "error_posting";
export const UNPAID = "unpaid";
export type EVENT_STATUS =
	| typeof REJECTED
	| typeof POSTED
	| typeof PENDING
	| typeof DRAFT
	| typeof DELETED
	| typeof ERROR_POSTING
	| typeof UNPAID;

export const POSSIBLE_EVENT_STATUS: readonly EVENT_STATUS[] = [
	REJECTED,
	POSTED,
	PENDING,
	DRAFT,
	DELETED,
	ERROR_POSTING,
	UNPAID,
];

export const eventDateSchema = z.object({
	startDate: z.date(),
	endDate: z.date(),
});

export type EventDate = z.infer<typeof eventDateSchema>;

export const statusConstellationSchema = z.object({
	destination: z.string(),
	status: z.enum([
		REJECTED,
		POSTED,
		PENDING,
		DRAFT,
		DELETED,
		ERROR_POSTING,
		UNPAID,
	]),
});
export type StatusConstellation = z.infer<typeof statusConstellationSchema>;

export const postOptionSchema = z.object({
	recoId: z.string().optional(),
	destination: z.string(),
	label: z.string(),
	selected: z.boolean().optional().default(false),
	hide: z.boolean().optional(),
	hideReason: z.string().optional(),
	pinned: z.boolean().optional(),
	hasRibbon: z.boolean().optional(),
});
export type PostOption = z.infer<typeof postOptionSchema>;

export const liveEventSchema = z.object({
	_id: z.string(),

	address: z.string(),

	bookingLink: z.string().optional(),
	bookProvider: z.string().optional(),

	categories: z.string().array(),
	city: z.string(),
	createdAt: dateSchema.or(z.string()),

	dates: eventDateSchema.array(),
	description: z.string(),
	destinationSlug: z.string(),

	email: z.string(),
	endDate: dateSchema.or(z.string()),
	expDate: dateSchema.or(z.string()),

	format: z.string().array().optional(),

	groups: z.string().array().optional(),

	halmstadKommunSupport: z.boolean().optional(),
	hide: z.boolean().optional(),
	hideReason: z.string().optional(),
	hideEventEndTime: z.boolean().optional().default(false),

	isConstellation: z.boolean().optional(),
	isFree: z.boolean(),
	isOnline: z.boolean(),
	isHybrid: z.boolean().optional(),
	isPinned: z.boolean().optional().default(false),
	isSponsored: z.boolean().optional().default(false),

	label: z.string().optional(),
	localEvent: z.boolean().optional(),
	longTerm: z.boolean().optional(),

	mapCoordinates: z.object({
		lat: z.number(),
		lng: z.number(),
	}),

	name: z
		.string()
		.refine((x) => !x.match(/[A-Z]{9,}/), "CE Max 8 capital letters"),

	organizer: z.object({
		name: z.string(),
		surname: z.string(),
		id: z.string(),
	}),
	organization: z.string().optional(),
	owner: z.string(),

	paid: z.boolean().optional(),
	phone: z.string(),
	photos: liveEventPhotoSchema.array(),
	placeId: z.string(),
	postalCode: z.string().optional(),
	postDate: dateSchema.or(z.string()),
	postOptions: postOptionSchema.array(),
	price: z.number().optional(),

	region: z.string().optional(),
	rejectionReason: z.string().optional(),
	rsvp: rsvpSchema,

	// External API ID
	scrapersId: z.string().optional(),
	// Eventim IDs from the same event (multiple dates)
	eventimIds: z.string().array().optional(),
	scrapersSource: availableApisSchema.optional(),
	sharedWith: z.string().array().optional(),
	startDate: dateSchema.or(z.string()),
	status: z.enum([
		REJECTED,
		POSTED,
		PENDING,
		DRAFT,
		DELETED,
		ERROR_POSTING,
		UNPAID,
	]),
	statusConstellation: statusConstellationSchema.array().optional(),

	targetGroups: z.string().array(),
	transactionId: z.string().optional(),

	updatedAt: dateSchema.or(z.string()),

	videos: videoSchema.array(),
	venue: z.string(),
	venueDescription: z.string().optional(),

	website: z.string(),
	socialMediaLinks: socialMediaSchema.array().optional(),
});
export type LiveEvent = z.infer<typeof liveEventSchema>;

export type PopulatedLiveEvent = LiveEvent & {
	RSVPRegistrations?: number;
};

export const subdomainArraySchema = z
	.object({
		destinationSlug: z.string(),
		subdomain: z.string(),
	})
	.array();

export type SubdomainArray = z.infer<typeof subdomainArraySchema>;
